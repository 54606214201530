import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import './Navbar.css';
import useDeviceDetect from "../utils/DeviceDetector";
import useDetectWidth from "../utils/WidthDetector";

const NavLinks = styled.div`
  transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(-100%)')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
`;

const NavLinkWrapper = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
`;

const NavbarLinks = () => {
  const { isMobile } = useDeviceDetect();
  const { screenWidth } = useDetectWidth();
  const [showLinks, setShowLinks] = React.useState(false);
  const [cross, setCross] = React.useState(false);
  const hamburgerLines = useRef([]);

  const toggleLinks = () => {
    setShowLinks((showLinks) => !showLinks);
    setCross((cross) => !cross);
    
    hamburgerLines.current.forEach(line => {
      line.classList.toggle('active');
    });
  };

  React.useEffect(() => {
    hamburgerLines.current = document.querySelectorAll('.hamburger-line');

    const handleClick = (event) => {
      // Check if the click is inside the Navbar container or the NavLinks container
      if (event.target.closest(".navbar-container") || event.target.closest(".nav-links") || !{isMobile}) {
        return;
      }

      // If the click is outside, close the NavLinks component
      setShowLinks(false);
      setCross(false);
      if(!showLinks){
        hamburgerLines.current.forEach(line => {
          line.classList.remove('active');
        });
      }
    };

    // Add the click event listener to the document object
    document.addEventListener("click", handleClick);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  return (
    <>
      <div className={`toggle-button${cross ? " active" : ""}`} onClick={toggleLinks}>
        <div className="hamburger-line" />
        <div className="hamburger-line" />
        <div className="hamburger-line" />
      </div>
      <NavLinks className="nav-links" show={showLinks || screenWidth > 768}>
        <NavLinkWrapper className="navlink-wrapper" show={showLinks || screenWidth > 768}>
          <a className="nav-link" id="appLauncher" href="/app">
            Launch App
          </a>
          <hr className="divider" />
          <a className="nav-link" href="/docs">
            Docs
          </a>
          <hr className="divider" />
          <a className="nav-link" href="#">
            Vision
          </a>
          <hr className="divider" />
          <a className="nav-link" href="/about">
            About
          </a>
        </NavLinkWrapper>
      </NavLinks>
    </>
  );
};


const Navbar = () => {
  return (
    <div className="navbar-container">
      <div className="logo" href="https://www.basedex.finance" />
      <NavbarLinks />
    </div>
  );
};

export default Navbar;
