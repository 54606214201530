import React from 'react';

function DexComponent() {
  console.log("testing dex component");
  return (
    <div>
      <h1>DexComponent</h1>
      {/* Add any additional components or content here */}
    </div>
  );
}

export default DexComponent;
