import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from './components/navbar/Navbar';
import HomePage from './components/homepage/HomePage';
import Footer from './components/footer/Footer';
import DexComponent from './web3/components/dex/Dex';

const App = () => {
  return (
    <Router>
      <Container>
        <HomePage/>
        <Navbar />
        <Routes>
          <Route exact path="/home" component={HomePage} />
          <Route exact path="/docs" component={DocsPage} />
          <Route exact path="/vision" component={VisionPage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/app" component={DappPage} />
        </Routes>
        <Footer />
      </Container>
    </Router>
  );
};

const Container = styled.div`
  font-family: sans-serif;
`;

const DocsPage = () => {
  return <Container>Docs Page</Container>;
};

const VisionPage = () => {
  return <Container>Vision Page</Container>;
};

const AboutPage = () => {
  return <Container>About Page</Container>;
};

const DappPage = () => {
  return <DexComponent />
};

export default App;
