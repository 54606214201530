import React from 'react';
import { FaTwitter, FaDiscord, FaEnvelope } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="icons-container">
          <a href="https://twitter.com/BaseDexFi" target="_blank" rel="noopener noreferrer">
            <FaTwitter className="icon" />
          </a>
          <a href="https://discord.gg/rgSbpW4bxc" target="_blank" rel="noopener noreferrer">
            <FaDiscord className="icon" />
          </a>
          <a href="mailto:contactus@basedex.com" rel="noopener noreferrer">
            <FaEnvelope className="icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
