import Navbar from '../navbar/Navbar';
import IntroText from '../introtext/IntroText';
import './HomePage.css'

const Home = () => {
  return (
    <div className="home-container">
      <Navbar />
      <IntroText />
    </div>
  );
};

export default Home;
